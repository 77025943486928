import { render, staticRenderFns } from "./RelationBill.vue?vue&type=template&id=54acf988&scoped=true"
import script from "./RelationBill.vue?vue&type=script&lang=js"
export * from "./RelationBill.vue?vue&type=script&lang=js"
import style0 from "./RelationBill.vue?vue&type=style&index=0&id=54acf988&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54acf988",
  null
  
)

export default component.exports